<script setup lang="ts">
const { to, noUtm } = defineProps<{
  to?: string;
  noUtm?: boolean;
  external?: boolean;
}>();

const storedUtmParams = ref("");

onMounted(() => {
  if (typeof window !== "undefined") {
    storedUtmParams.value = localStorage.getItem("utmParams") || "";
  }
});

const modifiedTo = computed(() => {
  if (storedUtmParams.value.length === 0 || noUtm) {
    return to; // Immediately returns 'to' if no UTM parameters are stored
  }

  // Extract the stored UTM parameters
  const utmQueryParams = storedUtmParams.value.substring(1); // Removes the initial '?'

  if (typeof to === "string") {
    // Removes existing parameters and adds the stored ones
    const urlWithoutQuery = to.split("?")[0]; // Removes any existing parameters
    return `${urlWithoutQuery}?${utmQueryParams}`;
  } else if (typeof to === "object" && to) {
    // Creates a new URL object without the existing query parameters
    return {
      path: to,
      query: Object.fromEntries(new URLSearchParams(utmQueryParams)),
    };
  }

  return to; // Default return
});
</script>

<template>
  <NuxtLink
    :to="modifiedTo"
    :target="external ? '_blank' : '_self'"
    rel="noopener noreferrer"
  >
    <slot></slot>
  </NuxtLink>
</template>
